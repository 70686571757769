import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { pagesSlice } from "../app/modules/MyPages/_redux/pagesSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  pages: pagesSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
